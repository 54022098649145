<template>
  <div class="config-param">
    <div class="config-param-cont">
      <div class="tit">请确认/修改试卷配置参数</div>
      <div class="sub-tit">配置参数:</div>
      <div class="display_flex mt10">
        <span class="label-name">试卷标题: </span>
        <div>
          <Input v-model="info.name" :disabled="!isEdit" placeholder="请输入试卷标题" style="width: 470px" />
        </div>
      </div>
      <div class="display_flex mt10">
        <span class="label-name">考试时间: </span>
        <div class="display_flex">
          <Input v-model="info.timelong" :disabled="!isEdit" placeholder="请输入考试时间" class="mr10 w120" />
          <span>分钟</span>
        </div>
      </div>
      <div class="display_flex">
        <div class="display_flex mt10">
          <span class="label-name">试卷总分: </span>
          <div class="display_flex">
            <Input v-model="info.total_score" disabled placeholder="请输入试卷总分" class="mr10 w120" />
            <span class="w68">分</span>
          </div>
        </div>
        <div class="display_flex mt10">
          <span class="label-name">及格分数: </span>
          <div class="display_flex">
            <Input v-model="info.pass_score" :disabled="!isEdit" placeholder="请输入及格分数" class="mr10 w120" />
            <span>分</span>
          </div>
        </div>
      </div>
      <!--    <div class="sub-tit">进入方式:</div>-->
      <!--    <div class="display_flex mt10">-->
      <!--      <div class="display_flex mr10">-->
      <!--        <img src="../../../../../assets/images/new_icon/wxdanxuan.png" width="16" height="16" class="mr10" />-->
      <!--        <p class="w68">随时进入</p>-->
      <!--      </div>-->
      <!--      <div class="display_flex">-->
      <!--        <img src="../../../../../assets/images/new_icon/xzdanxuan.png" width="16" height="16" class="mr10" />-->
      <!--        <p>固定时间进入（默认开始30分钟后禁止入内）</p>-->
      <!--      </div>-->
      <!--    </div>-->
      <div class="display_flex mt10">
        <span class="label-name">考试开始日期: </span>
        <el-date-picker
          @change="startTimeChange"
          size="small"
          v-model="info.startTime"
          type="datetime"
          placeholder="选择开始日期"
          :picker-options="dateOptions"
          align="right">
        </el-date-picker>
        <div style="margin-left: 10px;" v-show="isEdit">
          <el-button size="small" type="text" @click="getServerTime(1)">立即开始</el-button>
          <el-button size="small" type="text" @click="getServerTime(2)">下个整点</el-button>
          <el-button size="small" type="text" @click="getServerTime(3)">整体延后1小时</el-button>
        </div>
      </div>
      <div class="display_flex mt10">
        <span class="label-name">考试结束日期: </span>
        <el-date-picker
        @change="endTimeChange"
          size="small"
          v-model="info.endTime"
          type="datetime"
          placeholder="选择结束日期"
          :picker-options="dateOptions"
          align="right">
        </el-date-picker>
      </div>
      <!-- <div class="display_flex mt10">
        <span class="label-name">考试日期: </span>
        <DatePicker v-model="info.setDate" :disabled="!isEdit" :options="dateOptions" type="datetimerange" format="yyyy-MM-dd HH:mm" placeholder="请选择时间" style="width: 300px"></DatePicker>
        <div style="margin-left: 10px;" v-show="isEdit">
          <el-button size="small" type="text" @click="getServerTime(1)">立即开始</el-button>
          <el-button size="small" type="text" @click="getServerTime(2)">下个整点</el-button>
          <el-button size="small" type="text" @click="getServerTime(3)">整体延后1小时</el-button>
        </div>
      </div> -->
      <div class="sub-tit">做题方式:</div>
      <div class="display_flex mt10">
        <div class="display_flex mr10" v-for="item in typeList" :key="item.id" @click="changeType(item)">
          <img src="../../../../assets/images/new_icon/xzdanxuan.png" v-if="item.id == info.random_data_sort" width="16" height="16" class="mr10" />
          <img src="../../../../assets/images/new_icon/wxdanxuan.png" v-else width="16" height="16" class="mr10" />
          <p class="w68" style="cursor: pointer">{{ item.name }}</p>
        </div>
        <!--      <div class="display_flex">-->
        <!--        <img src="../../../../../assets/images/new_icon/xzdanxuan.png" width="16" height="16" class="mr10" />-->
        <!--        <p>顺序做题</p>-->
        <!--      </div>-->
      </div>
      <div class="sub-tit">答题说明:</div>
      <Input v-model="info.content" type="textarea" :disabled="!isEdit" :rows="6" placeholder="Enter something..." style="width: 540px"   class="mt10"/>
      <p class="sub-tit">分配方式</p>
      <div class="distribution mt10">
        <div class="distribution-tit">
          <img v-if="curDistribution == '1'" src="../../../../assets/images/new_icon/xzdanxuan.png"  @click="changeDistribution('1')" width="16" height="16" />
          <img v-else src="../../../../assets/images/new_icon/wxdanxuan.png"  @click="changeDistribution('1')" width="16" height="16" />
          <p  @click="changeDistribution('1')">选择分配班级（可多选）</p>
        </div>
        <Select v-model="groupIds" multiple style="width:300px" :disabled="curDistribution == '2' || !isEdit">
          <Option v-for="item in groupList" :value="item.group_id" :key="item.group_id">{{ item.group.name }}</Option>
        </Select>
      </div>
      <div class="distribution mt10">
        <div class="distribution-tit" >
          <img v-if="curDistribution == '2'" src="../../../../assets/images/new_icon/xzdanxuan.png" @click="changeDistribution('2')" width="16" height="16" />
          <img v-else src="../../../../assets/images/new_icon/wxdanxuan.png" @click="changeDistribution('2')" width="16" height="16" />
          <p @click="changeDistribution('2')">选择分配学生（可多选）</p>
        </div>
        <Select v-model="stuIds" multiple filterable style="width:300px" :disabled="curDistribution == '1' || !isEdit">
          <Option v-for="item in stuList" :value="item.user_id" :key="item.id">{{ item.user && item.user.nickname }}</Option>
        </Select>
      </div>
      <div class="bot-btn" v-if="isEdit">
        <div class="active" @click="pre">上一步</div>
        <div @click="save">保存并退出</div>
        <div class="active" @click="publish">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "configParam.vue",
  data(){
    return {
      value:'',
      animal:'',
      answerExp:'1. 时间安排：请同学们提前规划好答题时间，合理安排复习和休息时间。\n' +
          '2. 复习重点：请同学们重点复习教材中的重点知识点和难点，同时也要注意平时作业和练习题的复习。\n' +
          '3. 考试纪律：请同学们遵守纪律，不得抄袭、作弊等违规行为；\n' +
          '4. 考试心态：请同学们保持良好的考试心态，不要过于紧张或放松。',
      info:{},
      typeList:[
        {
          name:'打乱做题',
          id:'1'
        },
        {
          name:'顺序做题',
          id:'0'
        }
      ],
      dateOptions:{
        disabledDate (date) {
          return date && date.valueOf() < Date.now() - 86400000;
        }
      },
      type:'',
      taskId:'',
      examineId:'',
      curDistribution:'1',
      groupList:[],
      stuList:[],
      groupIds:[],
      stuIds:[],
      originTime: []
    }
  },
  props:{
    detail:{
      type:Object,
      default:()=>{
        return {};
      }
    },
    isEdit:{
      type:Boolean,
      default:true,
    },
    trainId:{
      type:String,
      default:'',
    },
    courseId:{
      type:String,
      default:'',
    }
  },
  watch:{
    detail:{
      handler(newV,oldV){
        if(newV.id){
          this.info = newV;
          this.initData();
        }
      },
      immediate:true,
    }
  },
  created(){
    this.type = this.$route.query.type || '';
    this.taskId = this.$route.query.taskId || '';
    this.examineId = this.$route.query.id || '';
    this.getGroupList();
    this.getUserList();
  },
  methods:{
    // 立即开始|下个整点|整体延后一小时
    getServerTime(type) {
      const { info, originTime } = this;
      console.log(info.setDate);
      if (type === 1) {
        info.startTime=new Date(this.info.present_time_stamp * 1000);
      }
      if (type === 2) {
        info.startTime=new Date(this.info.next_hour_time_stamp * 1000);
      }
      if (type === 3) {
        const start = new Date(originTime[0]).getTime()+ 60 * 60 * 1000;
        const end = new Date(originTime[1]).getTime() + 60 * 60 * 1000;
        info.startTime=new Date(start);
        info.endTime=new Date(end);
      }
    },
    // 开始时间改变
    startTimeChange(date){
      const { info } = this;
      if(!date) return;
      if(!info.endTime) return;
      if(date.getTime()>=info.endTime){
        this.$Message.error("开始日期不能大于结束日期");
        info.startTime=null;
      }
    },
    endTimeChange(date){
      const {info}=this;
      if(!date) return;
      if(!info.startTime) return;
      if(date.getTime()<=info.startTime){
        this.$Message.error("结束日期不能小于开始日期");
        info.endTime=null;
      }
    },
    initData(){
      if(this.info.start_time && this.info.end_time){
        // let setDate = [util.timeFormatter(
        //     new Date(+this.info.start_time * 1000),
        //     'yyyy-MM-dd hh:mm:ss'
        // ),util.timeFormatter(
        //     new Date(+this.info.end_time * 1000),
        //     'yyyy-MM-dd hh:mm:ss'
        // )];
        // this.$set(this.info,'setDate',setDate);
        this.originTime = [this.info.start_time*1000,this.info.end_time*1000];
        this.$set(this.info,"startTime",this.info.start_time*1000);
        this.$set(this.info,"endTime",this.info.end_time*1000);
        this.info.content = this.info.attr ? (this.info.attr.content || this.answerExp) : this.answerExp;
        this.curDistribution = this.info.attr.assign_type;
        if(this.curDistribution == 1){
          this.groupIds = this.info.attr.assign_value ? this.info.attr.assign_value.split(',') : [];
        }else{
          this.stuIds = this.info.attr.assign_value ? this.info.attr.assign_value.split(',') : [];
        }
        // this.info.random_data_sort
      }
    },
    changeType(data){
      if(!this.isEdit) return;
      this.info.random_data_sort = data.id;

    },
    submit(type,fn){ //提交数据
      if(!this.info.name){
        this.$Message.warning('请输入试卷标题');
        return;
      }
      if(!this.info.timelong){
        this.$Message.warning('请输入考试时间');
        return;
      }
      if(!this.info.total_score){
        this.$Message.warning('请输入试卷总分');
        return;
      }
      if(!this.info.pass_score){
        this.$Message.warning('请输入及格分数');
        return;
      }

      // if(!this.info.setDate.length){
      //   this.$Message.warning('请选择设置时间');
      //   return;
      // }
      if (!this.info.startTime) {
        this.$Message.warning('请选择开始日期');
        return;
      }
      if (!this.info.endTime) {
        this.$Message.warning('请选择结束日期');
        return;
      }
      // let startData = new Date(this.info.setDate[0]).getTime();
      // let nowDate = new Date().getTime();

      // if(nowDate > startData){
      //   this.$Message.warning('开始时间必须大于当前时间');
      //   return;
      // }

      if(!this.info.random_data_sort){
        this.$Message.warning('请选择做题方式');
        return;
      }

      if(this.curDistribution == 1){
        if(!this.groupIds.length){
          this.$Message.warning('请选择分配班级');
          return;
        }
      }else{

        if(!this.stuIds.length){
          this.$Message.warning('请选择分配学生');
          return;
        }
      }
      let params = {
        op:'baseInfo',
        name:this.info.name,
        timelong:this.info.timelong,
        total_score:this.info.total_score,
        pass_score:this.info.pass_score,
        // start_time:new Date(this.info.setDate[0]).getTime()/1000,
        // end_time:new Date(this.info.setDate[1]).getTime()/1000 + 86400 - 1,
        random_data_sort:this.info.random_data_sort,
        content:this.info.content,
        examine_id:this.examineId,
        generate_data_rule:this.type,  //2为固定模考 12为随机模考
        assign_type:this.curDistribution,
        start_time:this.info.startTime/1000,
        end_time:this.info.endTime/1000
      };
      if(this.curDistribution == 1){
        params.assign_value = this.groupIds.join(',');
      }else{
        params.assign_value = this.stuIds.join(',');
      }

      if(type == 'next'){   //下一步
        this.nextLoading = true;
      }else if(type == 'save'){ //保存并退出
        this.saveLoading = true;
      }
      this.api.course.examinePublish(params).then((res)=>{
        if(type == 'next'){   //下一步
          this.nextLoading = false;
          this.$emit('next',{trainId:res.train_id});
        }else if(type == 'save'){ //保存并退出
          this.saveLoading = false;
          this.$emit('save');
        }
        fn && fn();
      })
    },
    release(type){

      let params = {
        op:'release',
        examine_id:this.examineId,
        generate_data_rule:this.type,  //2为固定模考 12为随机模考
      };

      this.api.course.examinePublish(params).then((res)=>{
        this.$emit('publish');
      })
    },
    publish(){
      if(!this.isEdit) return;
      this.submit('publish',()=>{
        this.release();
      });
    },
    save(){
      this.submit('save');
    },
    pre(){
      this.$emit('pre')
    },
    getGroupList(){
      let params = {
        course_id: this.courseId
      };
      this.api.course.courseGroupList(params).then((res)=>{
        this.groupList = res.list;
      })
    },
    getUserList(){
      let params = {
        course_id: this.courseId,
        keyword:this.keyword
      };
      this.api.course.courseStudentList(params).then((res)=>{
        this.stuList = res.list;
      })
    },
    changeDistribution(data){
      this.curDistribution = data;
      this.groupIds = [];
      this.stuIds = [];
    },
  }
}
</script>

<style scoped lang="scss">
.config-param{
  height: 100%;
  font-size: 14px;
  .config-param-cont{
    padding: 20px;
    background: #FFFFFF;
    height: 100%;
    overflow-y: auto;

    .tit{
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .sub-tit{
      margin-top: 24px;
      font-weight: bold;
      color: #5782FF;
    }
    .distribution{
      .distribution-tit{
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        >img{
          margin-right: 10px;
        }
      }
    }
    .label-name{
      margin-right: 10px;
      font-weight: bold;
      color: #333333;
    }
    .display_flex{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .mt10{
      margin-top: 10px;
    }
    .mr10{
      margin-right: 10px;
    }
    .w120{
      width: 120px;
    }
    .w68{
      display: inline-block;
      width: 68px;
    }
    .bot-btn{
      margin-top: 60px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      >div{
        margin-left: 15px;
        width: 90px;
        height: 36px;
        border: 1px solid #999999;
        border-radius: 4px;
        color: #999999;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
      }
      .active{

        background: #4A79FF;
        border-radius: 4px;
        border:none;
        color: #FFFFFF;
      }
    }
  }
}
</style>
