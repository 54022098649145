<template>
<div class="base-info">
  <div class="base-info-nr">
    <div class="base-info-nr-l" >
      <p class="base-info-nr-l-tit">请选择试卷生成方式</p>
      <div v-if="task.id && detail.id">
        <BaseConfig :task="task" :mapId="mapId" :isEdit="isEdit" :levels="levels" :detail="detail" @change="changeData"></BaseConfig>
      </div>

    </div>
    <div class="base-info-nr-r">
      <TaskInfo :taskId="taskId" :task="task" :mapTree="task.children"></TaskInfo>
    </div>
  </div>
  <div class="dataList-r-btn" v-if="isEdit">
    <div class="active" @click="pre">上一步</div>
    <div @click="save">保存并退出</div>
    <div class="active" @click="next">下一步</div>
  </div>
</div>
</template>

<script>
  import TaskInfo from './taskInfo';
  import BaseConfig from './baseConfig';
  export default {
    name: "baseInfo",
    data(){
      return {
        taskId:'',
        task:{},
        mapTree:[],
        mapId:'',
        level:{},
        dataType:[],
        nextLoading:false,
        saveLoading:false,
        type:'',
        examineId:'',
      }
    },
    components:{
      TaskInfo,
      BaseConfig
    },
    props:{
      isEdit:{
        type:Boolean,
        default:true,
      },
      levels:{
        type:Array,
        default:()=>{
          return []
        },
      },
      detail:{
        type:Object,
        default:()=>{
          return {}
        },
      }
    },
    created(){
      this.taskId = this.$route.query.taskId || '';
      this.examineId = this.$route.query.id;
      this.type = this.$route.query.type || '';
      this.getDetail();
    },
    methods:{
      getDetail(){
        let params = {
          task_id:this.taskId,
          examine_id:this.examineId
        };
        this.api.course.courseTaskDetail(params).then((res)=>{
          this.task = res.task;
          this.mapId = res.map.id;
          this.mapTree = res.map_tree;
        })
      },
      changeData(data){
        this.level = data.level;
        this.dataType = data.types;

      },
      next(){
        if(!this.isEdit) return;
        this.submit('next');
      },
      save(){

        this.submit('save',true);
      },
      pre(){
        this.$emit('pre')
      },
      submit(type,unverify){
        if(type == 'next'){   //下一步
          if(this.nextLoading) return;
        }else if(type == 'save'){ //保存并退出
          if(this.saveLoading) return;
        }

        if(!unverify){

          let levelArr = this.level.filter((item)=>{
            return item.status;
          })
          if(!levelArr.length){
            this.$Message.warning('请选择试题难度');
            return;
          }

          let errArr = this.dataType.filter((item)=>{
            return Number(item.val) > Number(item.num);
          });
          if(errArr.length){
            this.$Message.warning('请输入正确试题数量');
            return;
          }

          let valArr = this.dataType.filter((item)=>{
            return Number(item.val) > 0;
          });
          if(valArr.length){

            let scoreArr = valArr.filter((item)=>{
              return item.score == 0;
            });
            if(scoreArr.length){
              this.$Message.warning('请输入分数');
              return;
            }
          }else{
            this.$Message.warning('请输入题目数量');
            return;
          }
        }
        let scoreStr = this.dataType.map((item)=>{
          return item.score;
        }).join(',');
        let numStr = this.dataType.map((item)=>{
          return item.val;
        }).join(',');

        let levelStr = this.level.filter((item)=>{
          return item.status;
        }).map((item)=>{
          return item.id;
        }).join(',');

        let params = {
          op:'dataRule',
          examine_id:this.examineId,
          score_str:scoreStr,
          type_str:numStr,
          level_str:levelStr,
          generate_data_rule:this.type,  //2为固定模考 12为随机模考
        };

        if(type == 'next'){   //下一步
          this.nextLoading = true;
        }else if(type == 'save'){ //保存并退出
          this.saveLoading = true;
        }

        this.api.course.examinePublish(params).then((res)=>{
          if(type == 'next'){   //下一步
            this.nextLoading = false;
            this.$emit('next');
          }else if(type == 'save'){ //保存并退出
            this.saveLoading = false;
            this.$emit('save');
          }

        }).catch((err)=>{
          if(type == 'next'){   //下一步
            this.nextLoading = false;
          }else if(type == 'save'){ //保存并退出
            this.saveLoading = false;
          }
        })
      },
    }
  }
</script>

<style scoped lang="scss">
.base-info{
  padding: 30px;
  background-color: #FFFFFF;
  .base-info-nr{
    position: relative;
    display: flex;
    justify-content: space-between;

    .base-info-nr-l{
      .base-info-nr-l-tit{
        margin-bottom: 28px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
    }
    .base-info-nr-r{
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .dataList-r-btn{
    margin-top: 60px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    >div{
      margin-left: 15px;
      width: 90px;
      height: 36px;
      border: 1px solid #999999;
      border-radius: 4px;
      color: #999999;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
    }
    .active{
      background: #4A79FF;
      border-radius: 4px;
      border:none;
      color: #FFFFFF;
    }
  }
}
</style>
