<template>
  <div class="material-create">
    <div style="height: 145px;padding: 20px;">
      <MyBreadcrumb></MyBreadcrumb>
      <div class="material-create-top">
        <div v-for="(item,index) in tabList" :key="item.id">
          <div class="tab-item" :class="curIndex == index ? 'active' : ''" @click="changeTab(index)">{{ item.name }}</div>
          <img src="../../assets/images/new_icon/you_gray.png" width="10" height="14" v-if="index < tabList.length - 1" />
        </div>
      </div>
    </div>


    <div class="material-create-cont" v-if="info.id">
      <component :is="tabList[curIndex].componentName" :categoryType="categoryType" :isEdit="isEdit" :detail="info" :levels="levels" :trainMatchRules="trainMatchRules" :trainTimelongLifes="trainTimelongLifes" :trainId="trainId" :categoryId="categoryId" :countStatus="countStatus" :courseId="courseId" @save="save" @next="next" @pre="pre" @publish="publish" @cancel="cancel"></component>
    </div>
  </div>
</template>

<script>
import BaseInfo from './components/create/baseInfo'
import DataList from './components/create/dataList'
import ConfigParam from './components/create/configParam'
export default {
  name: "materialCreate.vue",
  data(){
    return {
      tabList:[
        {
          name:'1.抽卷设置',
          id:1,
          componentName:'BaseInfo'
        },
        {
          name:'2.题目预览',
          id:2,
          componentName:'DataList'
        },
        {
          name:'3.配置参数',
          id:3,
          componentName:'ConfigParam'
        },
      ],
      curIndex:'',
      type:'',
      isEdit:true,
      trainId:'',
      info:{},
      trainMatchRules:{},
      trainTimelongLifes:{},
      categoryId:'',
      countStatus:false,
      tab:'',
      createType:'',
      levels:[],
      examineId:'',
      categoryType:'',
      courseId:'',
    }
  },
  components:{
    BaseInfo,
    DataList,
    ConfigParam
  },
  created(){
    this.examineId = this.$route.query.id || '';
    this.isEdit = this.$route.query.isEdit == 'false' ? false : true;
    this.getDetail();

    // if(this.$route.query.type){
    //   this.type = this.$route.query.type;
    //   this.isEdit = this.type == 'edit' ? true : false;
    // }


  },
  methods:{
    changeTab(index){
      if(!this.isEdit){
        this.curIndex = index + '';
      }
      if(this.curIndex > index){
        this.curIndex = index + '';
      }
    },
    getDetail(type){
      let params = {
        id:this.examineId
      };
      this.api.course.examineDetail(params).then((res)=>{
        this.info = res.info;
        if(res.next_hour_time_stamp){
          this.info.next_hour_time_stamp=res.next_hour_time_stamp;
        }
        if(res.present_time_stamp){
          this.info.present_time_stamp=res.present_time_stamp;
        }
        this.categoryId = this.info.generate_category_id;
        // this.trainMatchRules = res.train_match_rules;
        this.trainTimelongLifes = res.train_timelong_lifes;
        this.categoryType = res.category.type;
        this.trainId = res.train.id || '';
        this.courseId = res.course.id || '';

        this.levels = [];
        for(let name in res.levels){
          this.levels.push({
            id:name,
            name:res.levels[name]
          })
        }

        this.countStatus = res.data_count < res.generate_data_count;
        if(!type){
          if(res.info.release_status >= 2){
            this.curIndex = 2;
          }else{
            this.curIndex = res.info.release_status;
          }
        }

      })
    },
    pre(){
      // this.getDetail('pre');
      this.curIndex--;
      this.getDetail('pre');
    },
    next(data){
      this.curIndex++;
      this.getDetail('next');

    },
    save(){ //跳转列表页
      this.$router.go(-1)
    },
    publish(){  //跳转列表页

      this.$router.go(-1)
    },
    cancel(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.material-create{

  font-size: 14px;
  height: 100%;
  //display: flex;
  //justify-content: flex-start;
  //flex-direction: column;
  .material-create-top{
    padding: 0 20px;
    height: 84px;
    background: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    >div{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >img{
        margin: 0 10px;
      }
    }
    .tab-item{
      width: 138px;
      height: 42px;
      background: #F6F7FA;
      border-radius: 6px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      line-height: 42px;
      cursor: pointer;
    }
    .active{
      background: #4A79FF;
      color: #FFFFFF;
    }

  }
  .material-create-cont{
    //flex:1;
    margin: 20px;
    height: calc(100% - 165px);
    overflow-y: auto;
  }
}
</style>
