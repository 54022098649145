<template>
  <div style="position: relative;min-height: 300px">
    <div v-if="info.id" class="data-detail">
      <p class="data-type" :class="info.type == 1 ? 'bjFD0' : info.type == 2 ? 'purple' : 'bj3ff' ">{{ typesObj[info.type] }}</p>
      <Richtxt :dataObj="{val:info.title}" :isInline="true"></Richtxt>
      <div class="options" v-for="(item,index) in info.options">
        <div><span>{{optionIndex[index]}}</span><Richtxt :dataObj="{val:item.name}" :isInline="true"></Richtxt></div>

      </div>
      <div class="answer">
        <div class="mb20">
          <span class="tit">正确答案：</span>{{correctAnswer.join('')}}
        </div>
        <div v-if="info.explain">
          <p class="tit">题目解析：</p>
          <p ><Richtxt :dataObj="{val:info.explain}" :isInline="true"></Richtxt></p>
        </div>
      </div>
      <div>
        <div class="mb20">
          <span class="tit">难度：</span>{{levelObj[info.level]}}
        </div>
        <div>
          <p class="tit">考点：</p>
          <div class="nodes">
            <p v-for="item in info.labelKeypoints" :key="item.id">{{ item.label.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <Spin v-else fix></Spin>
  </div>


</template>

<script>
import Richtxt from '@/components/production/richtxt.vue';
export default {
  name: "dataDetail",
  data(){
    return {
      info:{},
      typesObj:{},
      levelObj:{},
      optionIndex:['A','B','C','D','E','F'],
      correctAnswer:[],
    }
  },
  props:{
    dataId:{
      type:String,
      default:''
    },
    trainId:{
      type:String,
      default:''
    }
  },
  watch:{
    dataId:{
      handler(newV){
        if(newV){
          this.getDetail();
        }
      },
      immediate:true,
    }
  },
  components:{
    Richtxt
  },
  created(){

  },
  methods:{
    getDetail(){
      let params = {
        train_id:this.$route.query.trainId || this.trainId,
        data_id:this.dataId
      }
      this.api.dataset.datasetTrainDataDetail(params).then((res)=>{
        this.info = res.info;
        this.typesObj = res.types;
        this.levelObj = res.levels;
        this.correctAnswer = [];
        this.info.options.forEach((item,index)=>{
          if(item.is_correct == '1'){
            this.correctAnswer.push(this.optionIndex[index]);
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.data-detail{
  padding: 0 20px;
  font-size: 14px;
  color: #333333;
  .data-type{
    margin-bottom: 5px;
    width: 40px;
    height: 26px;
    border-radius: 4px;
    text-align: center;
    line-height: 26px;
  }
  .options{
    margin: 20px 0;
    line-height: 24px;
    >div{
      margin-bottom: 5px;
      display: flex;
      justify-content: flex-start;
      >span{
        width: 20px;
        display: inline-block;
      }
    }
  }
  .answer{
    margin-bottom: 20px;
    padding: 20px 0;
    border-bottom: 1px solid #E8EAEC;
    border-top: 1px solid #E8EAEC;

  }
  .nodes{
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    >p{
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 0 12px;
      height: 26px;
      background: #EEEEEE;
      border-radius: 4px;
      color: #999999;
      line-height: 26px;
    }
  }
  .tit{
    font-weight: bold;
  }
  .mb20{
    margin-bottom: 20px;
  }
}
</style>
